<template>
  <WeEditor
    name="page-editor"
    v-bind:id="componentId"
    v-bind:access-token="session.accessToken"
    v-model="computedData"
  />
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "EditorColumn",
  props: {
    content: null,
  },
  model: {
    prop: "content"
  },
  computed: {
    ...mapState(["session"]),
    componentId() {
      return "we-row-column-" + helper.getRandomInteger(1, 999999);
    },
    computedData: {
      get() {
        return this.content;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>